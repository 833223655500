import React, { useState, useCallback, useEffect } from "react"
import {
  Card,
  FormLayout,
  TextField,
  Button,
  Icon,
  Layout,
  Stack,
  Popover,
  ActionList,
  Modal,
  TextStyle,
  ButtonGroup,
  Form,
  Checkbox,
  ChoiceList,
  Link,
} from "@shopify/polaris"
import {
  ImageMajor,
  TextBlockMajor,
  ColumnWithTextMajor,
  HeaderMajor,
  DeleteMajor,
  ChevronUpMinor,
  ChevronDownMinor,
} from "@shopify/polaris-icons"
import update from "immutability-helper"
import Divider from "../../../components/divider"
import SmartRules from "../offers/smartRules"
import CreateOfferSteps from "../offers/createOfferSteps"
import DocSave from "../../../components/docSave"
const CustomFieldForm = (props) => {
  const {
    token,
    host,
    shop,
    location,
    state,
    setState,
    productPickerShow,
    setProductPickerShow,
    currencySymbol,
    isCreating,
    isEditing,
    isSubmitting,
    hasUnsavedChanges,
    handleFormSubmit,
    handleCustomContentDelete,
  } = props
  const addSmartRule = () => {
    const conditions = {
      conditions: {
        all: [
          {
            any: [
              {
                fact: "cartTotal",
                operator: "greaterThanString",
                value: "0.00",
              },
            ],
          },
        ],
      },
      event: {
        type: "foundout",
        params: {
          message: "rule has found out!",
        },
      },
    }
    setState(update(state, { triggers: { $set: conditions } }))
  }
  //const formValues = getValues(fields)
  /* The form layout, created using Polaris and App Bridge components. */
  return (
    <Layout>
      <Layout.Section>
        {isCreating && (
        <div style={{ margin: "20px 0 10px -25px" }}>
          <CreateOfferSteps step={2} />
        </div>
        )}
        <div style={{ paddingRight: "3rem", marginBottom: 50 }}>
          <Form disabled={!state.name} onSubmit={handleFormSubmit}>
            <FormLayout>
              <Card sectioned>
                <FormLayout>
                  <h6>
                    <TextStyle variation="strong">Name Your Field</TextStyle>
                  </h6>
                  <TextField
                    value={state.name}
                    label="Name"
                    labelHidden
                    placeholder="Your shoppers won’t see this."
                    helpText={state.id ? `Unique ID: ${state.id}` : null}
                    onChange={(value) => {
                      setState(update(state, { name: { $set: value } }))
                    }}
                  />
                </FormLayout>
              </Card>
              <Card sectioned>
                <FormLayout>
                  <h6>
                    <TextStyle variation="strong">Field name</TextStyle>
                  </h6>
                  <TextField
                    value={state.fieldName}
                    multiline={4}
                    labelHidden
                    label="Field name"
                    onChange={(value) => {
                      setState(update(state, { fieldName: { $set: value } }))
                    }}
                  />
                  <h6>
                    <TextStyle variation="strong">Field placeholder</TextStyle>
                  </h6>
                  <TextField
                    value={state.fieldPlaceholder}
                    label="Field placeholder"
                    multiline={4}
                    labelHidden
                    onChange={(value) => {
                      setState(
                        update(state, { fieldPlaceholder: { $set: value } })
                      )
                    }}
                  />
                  <h6>
                    <TextStyle variation="strong">Character limit</TextStyle>
                  </h6>
                  <TextField
                    value={state.characterLimit}
                    label="Character limit"
                    labelHidden
                    onChange={(value) => {
                      setState(
                        update(state, { characterLimit: { $set: value } })
                      )
                    }}
                  />
                  <h6>
                    <TextStyle variation="strong">Checkbox</TextStyle>
                  </h6>
                  <Checkbox
                    checked={state.checkBox}
                    label="Checkbox"
                    onChange={(value) => {
                      setState(update(state, { checkBox: { $set: value } }))
                    }}
                  />

                  <h6>
                    <TextStyle variation="strong">Checkbox label</TextStyle>
                  </h6>
                  <TextField
                    value={state.checkboxLabel}
                    label="Checkbox label"
                    labelHidden
                    onChange={(value) => {
                      setState(
                        update(state, { checkboxLabel: { $set: value } })
                      )
                    }}
                  />
                  <h6>
                    <TextStyle variation="strong">Save field as</TextStyle>
                  </h6>
                  <ChoiceList
                    title="Save field as"
                    titleHidden
                    choices={[
                      {
                        label: "Order metafield (recommended)",
                        value: "metafield",
                      },
                    ]}
                    selected={state.fieldType}
                    onChange={(value) => {
                      setState(update(state, { fieldType: { $set: value } }))
                    }}
                  />
                  <p>
                    Add a metafield definition to easily see this data on your
                    orders.{" "}
                    <Link
                      external
                      url="https://help.shopify.com/en/manual/custom-data/metafields/metafield-definitions"
                      target="_blank"
                    >
                      Learn more
                    </Link>
                  </p>
                </FormLayout>
              </Card>
              <Card sectioned>
                <FormLayout>
                  <h6>
                    <TextStyle variation="strong">Display conditions</TextStyle>
                  </h6>
                  <SmartRules
                    host={host}
                    shop={shop}
                    token={token}
                    addSmartRule={addSmartRule}
                    state={state}
                    setState={setState}
                    productPickerShow={productPickerShow}
                    setProductPickerShow={setProductPickerShow}
                    currencySymbol={currencySymbol}
                    type={"customization"}
                    factsOptionsValue={[
                      { label: "Cart total", value: "cartTotal" },
                      { label: "Cart products", value: "cartProducts" },
                      { label: "Cart product QTY", value: "cartProductQTY" },
                      { label: "Customer", value: "customer" },
                      { label: "Discount code", value: "discountCode" },
                      { label: "Cart value", value: "cartValue" },
                    ]}
                  />
                </FormLayout>
              </Card>
            </FormLayout>
            <DocSave
              isCreating={isCreating}
              isEditing={isEditing}
              isSubmitting={isSubmitting}
              hasUnsavedChanges={hasUnsavedChanges}
              handleFormSubmit={handleFormSubmit}
              handleCustomContentDelete={handleCustomContentDelete}
              location={location}
            />
          </Form>
        </div>
      </Layout.Section>
      <Layout.Section secondary>
        <div
          style={{
            position: "fixed",
            marginRight: 30,
            width: 440,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Stack vertical>
            <p style={{ textAlign: "center" }}>Field preview</p>
            <Divider />
            <Card sectioned title={state?.fieldName}>
              <Stack gap="3" vertical>
                <FormLayout>
                  {state?.checkBox && <Checkbox label={state?.checkboxLabel} />}
                  <TextField
                    label="Field placeholder"
                    multiline={4}
                    labelHidden
                    placeholder={state?.fieldPlaceholder}
                  />
                </FormLayout>
              </Stack>
            </Card>
          </Stack>
        </div>
      </Layout.Section>
    </Layout>
  )
}

export default CustomFieldForm
