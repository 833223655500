import React, { useState } from 'react';
import { Page } from '@shopify/polaris';
import { withFirebase } from '../../../providers/firebase'
import { navigate } from "gatsby"
import equal from "deep-equal"
import Constants from '../../../helpers/constants'
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import getSymbolFromCurrency from 'currency-symbol-map';
import CustomFieldForm from './customFieldForm';
import createCustomization from '../../../helpers/createCustomization';
import DocStatus from '../../../components/docStatus';
import { updateCustomization, deleteCustomization } from '../../../helpers/updateCustomization';
function CreateCustomField(props) {
    const { token, shop, location, firebase, host } = props
    const existingCustomField = (location && location.state && location.state.customField) || null

    const isEditing = !!existingCustomField
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingCustomField && existingCustomField.id || null, // always null if not created
      name: existingCustomField && existingCustomField.name || '',
      fieldName: existingCustomField && existingCustomField.fieldName || '',
      fieldPlaceholder: existingCustomField && existingCustomField.fieldPlaceholder || '',
      characterLimit: existingCustomField && existingCustomField.characterLimit || '',
      checkboxLabel: existingCustomField && existingCustomField.checkboxLabel || '',
      checkBox: existingCustomField && existingCustomField.checkBox || false,
      fieldType: existingCustomField && existingCustomField.fieldType || ["metafield"],
      enabled: existingCustomField && existingCustomField.enabled || false,
      triggers: existingCustomField && existingCustomField.triggers || {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
      },
      type: "custom field",
    }
    const [state, setState] = useState(initialEditingState)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc
      );
      const [isSubmitting, setIsSubmitting] = useState(false)
      const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
      const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
      const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
      let currentState = Object.assign({},state);
      let initialStateForCheck = Object.assign({},initialEditingState);
      delete currentState.enabled;
      delete initialStateForCheck.enabled;
      delete currentState.updatedAt;
      delete initialStateForCheck.updatedAt;
      const hasUnsavedChanges = isEditing && !equal(currentState, initialStateForCheck)
      const handleFormSubmit = async (type) => {
        console.log("type", type)
        if (isSubmitting) {
          return
        }
        setIsSubmitting(true)
        if (isEditing) {
          await updateCustomization(state, shop, firebase)
          navigate(
            `/app/customfield/createCustomField`,
            {
              state: {customField: {...state, updatedAt: new Date().toISOString()}},
              replace: true,
            }
          )
        }else{
          console.log("saving")
          const result = await createCustomization({...state, enabled: "save" === type ? false : true}, token, shop, host)
          navigate(
            `/app/onboardingLastStep`,
            {
              state: {
                backLink:"/app/customizations", extensionName:"Custom field", id: result?.data?.id
              },
              replace: true,
            }
          )
        }
        setIsSubmitting(false)
      }
      const handleCustomContentDelete = async () => {
        await deleteCustomization(state.id, shop, firebase);
    
        navigate('/app/customizations', {
          state: location.state,
          replace: true,
        })
      }
      console.log("isEditing", isEditing)
    return (
        <Page>
          <DocStatus isEditing={isEditing} location={location} name={initialEditingState.name} updatedAt={existingCustomField?.updatedAt} handleFormSubmit={handleFormSubmit} isSubmitting={isSubmitting} hasUnsavedChanges={hasUnsavedChanges} token={token} shop={shop} firebase={firebase} state={state} setState={setState}/>
          <CustomFieldForm token={token} shop={shop} location={location} firebase={firebase} host={host} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} isCreating={isCreating} isEditing={isEditing} isSubmitting={isSubmitting} handleFormSubmit={handleFormSubmit} hasUnsavedChanges={hasUnsavedChanges} handleCustomContentDelete={handleCustomContentDelete}/>
        </Page>
    );
}

export default withFirebase(CreateCustomField);
